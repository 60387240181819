import customUriParser from './uriParser.js';

const baseFitmentFields = ['Year', 'Make', 'Model', 'Trim'];
const extraFitmentFields = [];

const searchBySizeFields = [
  'Extended Length A',
  'Force',
  'Compressed Length B',
  'Stroke C',
  'End Fitting Type',
  'End Fitting Size',
  'Finish',
];
const hardwareSearchFields = [
  'Application Support Mounting Point',
  'Application Support Mounting Size',
  'Mounting Method',
  'Thread Size',
  'Finish',
];

const searchByTextUrl = '/search/search-results/';
const searchBySizeUrl = '/custom-search/';
const searchByYMMTUrl = '/year-model-search/';
const searchByPartNumberUrl = '/part-number-search/';

const isVehicleLandingPage = !!window.document.querySelector('#cm_landing-page');
const isSearchByTextPage = window.location.pathname === searchByTextUrl;
const isSearchBySizePage = window.location.pathname === searchBySizeUrl;
const isSearchByYMMTPage = window.location.pathname === searchByYMMTUrl;
const isSearchByPartNumberPage = window.location.pathname === searchByPartNumberUrl;
const isHardwareSearchPage = !!window.document.querySelector('#cm_hardware-search');

window.Convermax.isSearchByTextPage = isSearchByTextPage;
window.Convermax.isSearchBySizePage = isSearchBySizePage;
window.Convermax.isSearchByYMMTPage = isSearchByYMMTPage;
window.Convermax.isSearchByPartNumberPage = isSearchByPartNumberPage;

function InitFunc() {
  if (isHardwareSearchPage) {
    localStorage.setItem('cm_view', '"list"');
  } else {
    const oldURIunitField = 'display_type';
    const unitValuesMap = {
      Grid: 'Inches',
      List: 'Millimeters',
      Both: 'Both',
    };
    const view = window.location.hash.includes(oldURIunitField)
      ? unitValuesMap[window.location.hash.split(`${oldURIunitField}=`)[1].split('&')[0]]
      : 'Inches';

    localStorage.setItem('cm_view', JSON.stringify(view));
  }
}

window.Convermax.showNToLBConversion = () => {
  window.document.querySelector('.conversion .conversion-show').style.display = 'none';
  window.document.querySelector('.conversion .conversion-hide').style.display = 'inline-block';
  window.document.querySelector('.conversion .conversion-content').style.display = 'table';
};
window.Convermax.hideNToLBConversion = () => {
  window.document.querySelector('.conversion .conversion-show').style.display = 'inline-block';
  window.document.querySelector('.conversion .conversion-hide').style.display = 'none';
  window.document.querySelector('.conversion .conversion-content').style.display = 'none';
  window.document.querySelector('.conversion #nxt-lbs').value = 0;
  window.document.querySelector('.conversion #nxt-newtons').value = 0;
};
window.Convermax.convertNToLB = (newtons) => {
  window.document.querySelector('.conversion #nxt-lbs').value = (newtons * 0.224808943870962).toFixed(0);
};

window.Convermax.addToCart = (e) => {
  if (e.target instanceof HTMLInputElement) {
    const formData = new FormData(e.target.closest('form'));
    window.stencilUtils.api.cart.itemAdd(formData, () => {
      window.location.assign('/cart.php');
    });
  }
};
window.Convermax.addKitToCart = (kitData) => {
  const [hoodItem, trunkItem] = kitData;

  const hoodItemForm = new FormData();
  hoodItemForm.append('action', 'add');
  hoodItemForm.append('product_id', hoodItem.id);
  hoodItemForm.append('qty[]', hoodItem.qty);

  window.stencilUtils.api.cart.itemAdd(hoodItemForm, () => {
    const trunkItemForm = new FormData();
    trunkItemForm.append('action', 'add');
    trunkItemForm.append('product_id', trunkItem.id);
    trunkItemForm.append('qty[]', trunkItem.qty);

    window.stencilUtils.api.cart.itemAdd(trunkItemForm, () => {
      window.location.assign('/cart.php');
    });
  });
};

function getLocalPreselection(pageType, defaults) {
  const excludeFromSearchByYmmt = [
    {
      field: 'exclude_from_search_by_ymmt',
      term: 'True',
      op: 'NOT',
    },
  ];

  if (isSearchByYMMTPage) {
    return excludeFromSearchByYmmt;
  }

  if (isVehicleLandingPage) {
    return [...excludeFromSearchByYmmt, ...defaults.getter(pageType)];
  }

  if (isSearchBySizePage) {
    return [
      {
        field: 'exclude_from_search_by_size',
        term: 'True',
        op: 'NOT',
      },
    ];
  }

  if (isHardwareSearchPage) {
    return [
      {
        field: 'include_in_hardware_search',
        term: 'True',
      },
    ];
  }

  return defaults.getter(pageType);
}

const onVehicleSelected = () => {
  if (isSearchByYMMTPage && window.innerHeight <= 1200) {
    const searchResult = window.document.querySelector('#itemsBlock');

    window.scrollTo(
      0,
      searchResult.getBoundingClientRect().top +
        searchResult.ownerDocument.defaultView.scrollY -
        (window.document.querySelector('.sticky-header')?.offsetHeight || 100) -
        20,
    );
  }
};

function setFilterChipsTop() {
  const resultsTableHeader = window.document.querySelector('.cm_SearchResult thead');
  const filterChips = window.document.querySelector('.cm_sticky-filter-chips');

  if (resultsTableHeader && filterChips) {
    filterChips.style.top = `${resultsTableHeader.getBoundingClientRect().top}px`;

    if (window.getComputedStyle(filterChips).getPropertyValue('display') === 'none') {
      filterChips.style.display = 'flex';
    }
  }
}

const AfterInit = () => {
  if (isSearchBySizePage || isHardwareSearchPage) {
    const checkExist = setInterval(function () {
      const filterChips = window.document.querySelector('.cm_sticky-filter-chips');

      if (filterChips && window.getComputedStyle(filterChips).getPropertyValue('display') === 'none') {
        setFilterChipsTop();
      } else {
        clearInterval(checkExist);
      }
    }, 100);

    window.document.addEventListener('scroll', () => setFilterChipsTop());
  }
};

export default {
  platform: 'bigcommerce',
  InitFunc,
  AfterInit,
  getLocalPreselection,
  customUriParser,
  defaultView: isHardwareSearchPage ? 'list' : 'Inches',
  searchPageUrl: searchByTextUrl,
  SearchRequestDefaults: {
    pageSize: isSearchBySizePage
      ? 15
      : isSearchByYMMTPage || isVehicleLandingPage || isSearchByPartNumberPage
        ? 12
        : 36,
  },
  page: {
    getPageType: (defaults) => (isVehicleLandingPage ? 'category' : defaults.getter()),
  },
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoRedirectDisabled: true,
    isAutoVehicleSelectionDisabled: true,
    isVehicleSelectionIsolated: !isVehicleLandingPage,
    hideVehicleFromUrl: !isSearchByYMMTPage,
    onVehicleSelected,
  },
  facets: {
    rangedFacet: [
      {
        fields: ['Extended Length A', 'Force', 'Compressed Length B', 'Stroke C'],
        name: 'searchBySizeFacet',
        step: '1/100//2',
      },
    ],
    simpleFacet: [{ fields: ['End Fitting Type'], name: 'tilesFacet' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'cm_search-by-text' },
      template: 'SearchByText/SearchPage',
    },
    {
      name: 'SearchBySize',
      type: 'SearchPage',
      location: { selector: '#cm_size-search', class: 'cm_search-by-size' },
      template: 'SearchBySize/SearchPage',
    },
    {
      name: 'YMMTSearchPage',
      type: 'SearchPage',
      location: { selector: '#cm_ymmt-search', class: 'cm_search-by-ymmt' },
      template: 'SearchByYMMT/SearchPage',
    },
    {
      name: 'VehicleLandingPage',
      type: 'SearchPage',
      location: { selector: '#cm_landing-page', class: 'cm_landing-page' },
      template: 'VehicleLandingPages/SearchPage',
    },
    {
      name: 'HardwareSearchPage',
      type: 'SearchPage',
      location: { selector: '#cm_hardware-search', class: 'cm_hardware-search' },
      template: 'HardwareSearch/SearchPage',
    },
    {
      name: 'VehicleTiles',
      visibleIf: () => isVehicleLandingPage,
    },
    {
      name: 'PartNumberSearchPage',
      type: 'SearchPage',
      location: {
        selector: '#cm_part-number-search',
        class: 'cm_search-by-part-number',
      },
      template: 'SearchByPartNumber/SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': isSearchBySizePage ? 'Recommended' : 'Relevance',
        'sku': 'SKU',
        'Extended Length A': 'Extended Length',
        'Stroke C': 'Stroke Length',
        'Compressed Length B': 'Compressed Length',
        'Force': 'Force',
        'End Fitting Type': 'Shaft Connector',
        'End Fitting Size': 'Body Connector',
        'price': 'Price [Low - High]',
        'price:desc': 'Price [High - Low]',
      },
      sortSelectClass: 'form-select form-select--small',
      pageSizes: isSearchByPartNumberPage ? [12, 24, 36] : [15, 25, 50, 100],
      pageSizeSelectClass: 'form-select form-select--small form-select-narrow',
    },
    {
      name: 'CategorySidePanel',
      type: 'SearchPage',
      location: 'body.page--category .page-sidebar.category-sidebar',
      template: 'SidePanel',
    },
    {
      name: 'FacetPanel',
      fields: isSearchBySizePage ? searchBySizeFields : isHardwareSearchPage ? hardwareSearchFields : [],
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      location: '.header-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '#cm_mobile-search-box', class: 'navUser-action' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: {
        replace: '#cm_garage',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart',
      },
      template: 'fitmentSearch/garage',
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'GarageMobile',
      type: 'Garage',
      location: {
        replace: '#cm_mobile-garage',
      },
      template: 'fitmentSearch/garage',
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'PartNumberSearchBox',
      type: 'SearchBox',
      disableDropdown: true,
      redirectUrl: searchByPartNumberUrl,
    },
    {
      name: 'SearchVehicleWidget_sidepanel',
      type: 'VehicleWidget',
      visibleIf: () => isSearchByYMMTPage,
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'SearchVehicleWidget_label',
      type: 'VehicleWidget',
      visibleIf: () => isSearchByYMMTPage,
    },
    {
      name: 'HomeVehicleWidget_hometab',
      type: 'VehicleWidget',
      location: {
        selector: '.cm_tab-content.cm_ymmt-search',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeTabs/vehicleWidget',
      visibleIf: () => window.location.pathname === '/',
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'SearchBox_hometab-part-number',
      type: 'SearchBox',
      location: {
        selector: '.cm_tab-content.cm_part-number-search',
        class: 'cm_search-box-root__tab',
      },
      template: 'SearchBox',
      disableDropdown: true,
      redirectUrl: searchByPartNumberUrl,
    },
    {
      name: 'SearchBox_hometab-vin',
      type: 'SearchBox',
      location: {
        selector: '.cm_tab-content.cm_vin-search',
        class: 'cm_search-box-root__tab',
      },
      template: 'SearchBox',
      disableDropdown: true,
      redirectUrl: searchByYMMTUrl,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '#cm-fitment-table-tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      fields: isSearchBySizePage ? searchBySizeFields : isHardwareSearchPage ? hardwareSearchFields : [],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
  ],
};

import { SearchPage } from 'Components'
import TemplateSearchByTextSearchPage from 'Stores/liftsupportsdepot/Templates/SearchByText/SearchPage.rt'
import TemplateSearchBySizeSearchPage from 'Stores/liftsupportsdepot/Templates/SearchBySize/SearchPage.rt'
import TemplateSearchByYMMTSearchPage from 'Stores/liftsupportsdepot/Templates/SearchByYMMT/SearchPage.rt'
import TemplateVehicleLandingPagesSearchPage from 'Stores/liftsupportsdepot/Templates/VehicleLandingPages/SearchPage.rt'
import TemplateHardwareSearchSearchPage from 'Stores/liftsupportsdepot/Templates/HardwareSearch/SearchPage.rt'
import { VehicleTiles } from 'Components'
import TemplateSearchByPartNumberSearchPage from 'Stores/liftsupportsdepot/Templates/SearchByPartNumber/SearchPage.rt'
import { SearchHeader } from 'Components'
import TemplateSidePanel from 'Stores/liftsupportsdepot/Templates/SidePanel.rt'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { VehicleWidget } from 'Components'
import TemplateHomeTabsvehicleWidget from 'Stores/liftsupportsdepot/Templates/HomeTabs/vehicleWidget.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import TemplatefitmentSearchfitmentTableTab from 'Stores/liftsupportsdepot/Templates/fitmentSearch/fitmentTableTab.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/liftsupportsdepot/Templates/fitmentSearch/verifyFitment.rt'

export const compMap = {
  SearchPage,
VehicleTiles,
SearchHeader,
FacetPanel,
SearchResult,
SearchBox,
SearchBoxDialogButton,
Garage,
VehicleWidget,
FitmentTable,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchByText/SearchPage': TemplateSearchByTextSearchPage,
'SearchBySize/SearchPage': TemplateSearchBySizeSearchPage,
'SearchByYMMT/SearchPage': TemplateSearchByYMMTSearchPage,
'VehicleLandingPages/SearchPage': TemplateVehicleLandingPagesSearchPage,
'HardwareSearch/SearchPage': TemplateHardwareSearchSearchPage,
'SearchByPartNumber/SearchPage': TemplateSearchByPartNumberSearchPage,
'SidePanel': TemplateSidePanel,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'HomeTabs/vehicleWidget': TemplateHomeTabsvehicleWidget,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'fitmentSearch/fitmentTableTab': TemplatefitmentSearchfitmentTableTab,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment
};

        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title-container' }, _createElement('div', { 'className': 'dialog-title' }, 'Filters'), _createElement('div', { 'className': 'total-hits' }, this.totalHits, ' Results')), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-clear button',
        'onClick': this.clear
    }, '\n          Clear\n        '), _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-done button',
        'onClick': this.close
    }, '\n          Done\n        '))), this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '5771'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7210'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2640'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'CURRENTLY SHOPPING BY'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn button cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Clear All\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function scopeNameArrTitleUnits3() {
                                var nameArr = this.name.split('(');
                                var title = nameArr[0];
                                var units = nameArr[1].replace(')', '').toLowerCase();
                                return _createElement('div', {
                                    'className': 'cmTemplate_searchBySizeFacet',
                                    'key': '3817'
                                }, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'cm_Inputs' }, _createElement('div', { 'className': 'input-container' }, '\n    Minimum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMin(function () {
                                                return _createElement('div', { 'className': 'input cm_inputMin' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], '\n      ', units, '\n    ')), _createElement('div', { 'className': 'input-container' }, '\n    Maximum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMax(function () {
                                                return _createElement('div', { 'className': 'input cm_inputMax' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], '\n      ', units, '\n    ')), _createElement('button', {
                                            'type': 'button',
                                            'className': 'button',
                                            'onClick': this.setCustomRange
                                        }, 'SEARCH'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.name === 'Force (LBS)' ? _createElement('div', {
                                    'className': 'conversion',
                                    'key': '5392'
                                }, _createElement('a', {
                                    'className': 'conversion-show nxt-no-address',
                                    'style': { display: 'inline-block' },
                                    'onClick': () => window.Convermax.showNToLBConversion()
                                }, _createElement('i', { 'className': 'fa fa-info' }), ' Click here to convert Newtons (N) to Pounds (lbs)\n  '), _createElement('a', {
                                    'className': 'conversion-hide nxt-no-address',
                                    'onClick': () => window.Convermax.hideNToLBConversion()
                                }, _createElement('i', { 'className': 'fa fa-close' }), ' Hide\n  '), _createElement('div', { 'className': 'conversion-content' }, _createElement('p', {}, 'Convert Newtons (N) to Pounds (lbs):'), _createElement('p', {}, _createElement('input', {
                                    'id': 'nxt-newtons',
                                    'type': 'number',
                                    'placeholder': '0',
                                    'onChange': e => window.Convermax.convertNToLB(e.target.value)
                                }), _createElement('span', {}, 'N')), _createElement('p', {}, _createElement('input', {
                                    'disabled': true,
                                    'id': 'nxt-lbs',
                                    'type': 'number',
                                    'placeholder': '0'
                                }), _createElement('span', {}, 'lbs')))) : null);
                            }
                            function repeatShowAlwaysValues4(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                            'className': 'facetimg',
                                            'src': this.imageUrl,
                                            'onError': event => event.target.removeAttribute('src')
                                        }), _createElement('div', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                    }, { count: undefined })];
                            }
                            function repeatValues5(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                            'className': 'facetimg',
                                            'src': this.imageUrl,
                                            'onError': event => event.target.removeAttribute('src')
                                        }), _createElement('div', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? _createElement('rt-virtaul', { 'key': '55' }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle left',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '2360'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle down',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '5570'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '1083'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '1169'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '1489'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '16581'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '3489'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'searchBySizeFacet' ? scopeNameArrTitleUnits3.apply(this, []) : null, this.template === 'tilesFacet' ? _createElement('div', {
                                'className': 'cmTemplate_tilesFacet',
                                'key': '6222'
                            }, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues4.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues5.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '8045'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null)) : null, this.template === 'toggleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_toggleFacet',
                                'key': '8387'
                            }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                'className': 'cm_facet-toggle_input',
                                'type': 'checkbox',
                                'checked': this.isToggled,
                                'onClick': this.toggleFacet
                            }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null, !this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '186741'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7210'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'CURRENTLY SHOPPING BY'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn button cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Clear All\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function scopeNameArrTitleUnits3() {
                                var nameArr = this.name.split('(');
                                var title = nameArr[0];
                                var units = nameArr[1].replace(')', '').toLowerCase();
                                return _createElement('div', {
                                    'className': 'cmTemplate_searchBySizeFacet',
                                    'key': '3817'
                                }, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'cm_Inputs' }, _createElement('div', { 'className': 'input-container' }, '\n    Minimum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMin(function () {
                                                return _createElement('div', { 'className': 'input cm_inputMin' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], '\n      ', units, '\n    ')), _createElement('div', { 'className': 'input-container' }, '\n    Maximum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMax(function () {
                                                return _createElement('div', { 'className': 'input cm_inputMax' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], '\n      ', units, '\n    ')), _createElement('button', {
                                            'type': 'button',
                                            'className': 'button',
                                            'onClick': this.setCustomRange
                                        }, 'SEARCH'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.name === 'Force (LBS)' ? _createElement('div', {
                                    'className': 'conversion',
                                    'key': '5392'
                                }, _createElement('a', {
                                    'className': 'conversion-show nxt-no-address',
                                    'style': { display: 'inline-block' },
                                    'onClick': () => window.Convermax.showNToLBConversion()
                                }, _createElement('i', { 'className': 'fa fa-info' }), ' Click here to convert Newtons (N) to Pounds (lbs)\n  '), _createElement('a', {
                                    'className': 'conversion-hide nxt-no-address',
                                    'onClick': () => window.Convermax.hideNToLBConversion()
                                }, _createElement('i', { 'className': 'fa fa-close' }), ' Hide\n  '), _createElement('div', { 'className': 'conversion-content' }, _createElement('p', {}, 'Convert Newtons (N) to Pounds (lbs):'), _createElement('p', {}, _createElement('input', {
                                    'id': 'nxt-newtons',
                                    'type': 'number',
                                    'placeholder': '0',
                                    'onChange': e => window.Convermax.convertNToLB(e.target.value)
                                }), _createElement('span', {}, 'N')), _createElement('p', {}, _createElement('input', {
                                    'disabled': true,
                                    'id': 'nxt-lbs',
                                    'type': 'number',
                                    'placeholder': '0'
                                }), _createElement('span', {}, 'lbs')))) : null);
                            }
                            function repeatShowAlwaysValues4(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                            'className': 'facetimg',
                                            'src': this.imageUrl,
                                            'onError': event => event.target.removeAttribute('src')
                                        }), _createElement('div', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                    }, { count: undefined })];
                            }
                            function repeatValues5(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                            'className': 'facetimg',
                                            'src': this.imageUrl,
                                            'onError': event => event.target.removeAttribute('src')
                                        }), _createElement('div', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? _createElement('rt-virtaul', { 'key': '55' }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle left',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '2360'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle down',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '5570'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '1083'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '1169'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '1489'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '16581'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '3489'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'searchBySizeFacet' ? scopeNameArrTitleUnits3.apply(this, []) : null, this.template === 'tilesFacet' ? _createElement('div', {
                                'className': 'cmTemplate_tilesFacet',
                                'key': '6222'
                            }, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues4.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues5.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '8045'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null)) : null, this.template === 'toggleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_toggleFacet',
                                'key': '8387'
                            }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                'className': 'cm_facet-toggle_input',
                                'type': 'checkbox',
                                'checked': this.isToggled,
                                'onClick': this.toggleFacet
                            }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null));
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets"]
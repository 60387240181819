
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_hardware-search__search-page' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '21941' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                    '\n        for your ',
                    _createElement('b', { 'key': '22951' }, '\'', this.vehicleString, '\'.')
                ] : null, this.query ? [
                    ' for the entered VIN number ',
                    _createElement('b', { 'key': '24421' }, '\'', this.query, '\'.')
                ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '25781' }, '\n      No results were found for your\n      ', this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '26891' }, '\'', this.query, '\'.')
                ] : null, !this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '27801' }, '\'', this.search, '\'.')
                ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '2908'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3123'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3352'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '34980' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '35850' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '36680' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '34634' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_sticky-filter-chips cm_mobile-hide cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('div', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':')), _createElement('ul', { 'className': 'facetentryval' }, _createElement('li', { 'className': 'cm_chip-value' }, '\n          ', this.value, '\n          ', _createElement('span', { 'className': 'fa fa-times-circle' })))) : this.template === 'rangeChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('div', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':')), _createElement('ul', { 'className': 'facetentryval' }, _createElement('li', { 'className': 'cm_chip-value' }, this.finite ? _createElement(_Fragment, { 'key': '253' }, this.selectedRange[0], ' to ', this.selectedRange[1]) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '367' }, this.selectedRange[0], ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '465' }, this.selectedRange[1], ' and less') : null, _createElement('span', { 'className': 'fa fa-times-circle' })))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'CURRENTLY SHOPPING BY'), _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', { 'className': 'button-container' }, _createElement('div', {
                        'className': 'button',
                        'data-cm-role': 'start-over'
                    }, 'CLEAR ALL')));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], !this.isLoading && this.totalHits > 0 ? [
        [this.FacetPanel(function () {
                return _createElement('div', { 'className': 'cm_FacetPanel' }, [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                    'type': 'checkbox',
                                                    'className': 'cm_facet_checkbox',
                                                    'readOnly': true,
                                                    'checked': this.isSelected
                                                }), _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                            }, { count: undefined })];
                                    }
                                    function scopeNameArrTitleUnits3() {
                                        var nameArr = this.name.split('(');
                                        var title = nameArr[0];
                                        var units = nameArr[1].replace(')', '').toLowerCase();
                                        return _createElement('div', {
                                            'className': 'cmTemplate_searchBySizeFacet',
                                            'key': '3817'
                                        }, [this.Inputs(function () {
                                                return _createElement('div', { 'className': 'cm_Inputs' }, _createElement('div', { 'className': 'input-container' }, '\n    Minimum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMin(function () {
                                                        return _createElement('div', { 'className': 'input cm_inputMin' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], '\n      ', units, '\n    ')), _createElement('div', { 'className': 'input-container' }, '\n    Maximum ', title, ':\n    ', _createElement('span', { 'className': 'inner-container' }, [this.inputMax(function () {
                                                        return _createElement('div', { 'className': 'input cm_inputMax' });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })], '\n      ', units, '\n    ')), _createElement('button', {
                                                    'type': 'button',
                                                    'className': 'button',
                                                    'onClick': this.setCustomRange
                                                }, 'SEARCH'));
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], this.name === 'Force (LBS)' ? _createElement('div', {
                                            'className': 'conversion',
                                            'key': '5392'
                                        }, _createElement('a', {
                                            'className': 'conversion-show nxt-no-address',
                                            'style': { display: 'inline-block' },
                                            'onClick': () => window.Convermax.showNToLBConversion()
                                        }, _createElement('i', { 'className': 'fa fa-info' }), ' Click here to convert Newtons (N) to Pounds (lbs)\n  '), _createElement('a', {
                                            'className': 'conversion-hide nxt-no-address',
                                            'onClick': () => window.Convermax.hideNToLBConversion()
                                        }, _createElement('i', { 'className': 'fa fa-close' }), ' Hide\n  '), _createElement('div', { 'className': 'conversion-content' }, _createElement('p', {}, 'Convert Newtons (N) to Pounds (lbs):'), _createElement('p', {}, _createElement('input', {
                                            'id': 'nxt-newtons',
                                            'type': 'number',
                                            'placeholder': '0',
                                            'onChange': e => window.Convermax.convertNToLB(e.target.value)
                                        }), _createElement('span', {}, 'N')), _createElement('p', {}, _createElement('input', {
                                            'disabled': true,
                                            'id': 'nxt-lbs',
                                            'type': 'number',
                                            'placeholder': '0'
                                        }), _createElement('span', {}, 'lbs')))) : null);
                                    }
                                    function repeatShowAlwaysValues4(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                                    'className': 'facetimg',
                                                    'src': this.imageUrl,
                                                    'onError': event => event.target.removeAttribute('src')
                                                }), _createElement('div', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues5(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('img', {
                                                    'className': 'facetimg',
                                                    'src': this.imageUrl,
                                                    'onError': event => event.target.removeAttribute('src')
                                                }), _createElement('div', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem'
                                                }, '\n        ', this.value, '\n        ', _createElement('div', { 'className': 'facetentryval' }, this.hitCount)));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? _createElement('rt-virtaul', { 'key': '55' }, _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '2360'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '5570'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1083'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '1169'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input  cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1489'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '16581'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3489'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'searchBySizeFacet' ? scopeNameArrTitleUnits3.apply(this, []) : null, this.template === 'tilesFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_tilesFacet',
                                        'key': '6222'
                                    }, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues4.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues5.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '8045'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null)) : null, this.template === 'toggleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_toggleFacet',
                                        'key': '8387'
                                    }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                        'className': 'cm_facet-toggle_input',
                                        'type': 'checkbox',
                                        'checked': this.isToggled,
                                        'onClick': this.toggleFacet
                                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('div', {
            'className': 'cm_main-content',
            'key': '107703'
        }, _createElement('main', {
            'id': 'itemsBlock',
            'className': 'page-content'
        }, [this.SearchHeader(function () {
                return _createElement('div', { 'className': 'cm_search-by-size__search-header cm_SearchHeader' }, _createElement('div', { 'className': 'listing-header group' }, _createElement('p', { 'className': 'results-info' }, '\n    Items ', _createElement('b', {}, this.pageFrom), ' to ', _createElement('b', {}, this.pageTo), ' of a total ', _createElement('b', {}, this.totalHits)), _createElement('ul', { 'className': 'product-view' }, _createElement('li', {}, 'View As:'), _createElement('li', {}, _createElement('a', {
                    'className': 'fa fa-th' + (this.view === 'grid' ? ' active' : ''),
                    'onClick': this.setView('grid')
                })), _createElement('li', {}, _createElement('a', {
                    'className': 'fa fa-list' + (this.view === 'list' ? ' active' : ''),
                    'onClick': this.setView('list')
                }))), _createElement('div', { 'className': 'cm_sort sort-fieldset' }, _createElement('label', { 'className': 'form-label' }, 'Sort by: '), '\n    ', this.sortSelect, '\n  '), [this.facetDialogButton(function () {
                        return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                                return _createElement('div', { 'className': 'button cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '-5 0 394 394.00003',
                                        'key': '520'
                                    }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '0 0 247.46 247.46',
                                        'key': '7930'
                                    }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]));
            }, {
                widgetName: 'search-header',
                items: undefined
            })], [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function repeatI1(i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'icon ' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'icon--ratingEmpty' : this.review_average - i < 0.75 ? 'icon--ratingHalf' : 'icon--ratingFull')
                                }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                            }
                            function repeatI2(i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'icon ' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'icon--ratingEmpty' : this.review_average - i < 0.75 ? 'icon--ratingHalf' : 'icon--ratingFull')
                                }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-star' })));
                            }
                            return _createElement('li', { 'className': 'product' }, this.view === 'grid' ? [_createElement('article', {
                                    'className': 'card',
                                    'key': '360'
                                }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', { 'href': this.custom_url }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                                    'className': 'card-image lazyautosizes lazyloaded',
                                    'src': this.imageOrDefault(this.images_image_thumbnail1 || this.images_image_thumbnail2),
                                    'alt': this.removeHTML(this.name),
                                    'title': this.removeHTML(this.name),
                                    'onError': this.onImageError
                                })))), _createElement('div', { 'className': 'card-body' }, _createElement('h4', { 'className': 'card-title' }, _createElement('a', { 'href': this.custom_url }, _createElement('span', { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', {
                                    'className': 'card-text',
                                    'data-test-info-type': 'price'
                                }, _createElement('div', { 'className': 'productView-rating' }, [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'productView-rating',
                                            'key': '7710'
                                        },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1.bind(this)),
                                        _createElement('span', { 'className': 'productView-reviewLink' }, !this.review_count ? ['(No reviews yet)'] : null, this.review_count ? [
                                            '(',
                                            this.review_count,
                                            ' reviews)'
                                        ] : null)
                                    ])]), _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                                    'data-product-rrp-without-tax': true,
                                    'className': 'price price--rrp'
                                }, '\n          ', this.formatPrice(this.retail_price), '\n        '), _createElement('span', {
                                    'data-product-price-without-tax': true,
                                    'className': 'price price--withoutTax'
                                }, '\n          ', this.formatPrice(this.price), ' ', this.Quantity_Sold_As, '\n        ')))))] : null, this.view === 'list' ? [_createElement('article', {
                                    'className': 'listItem',
                                    'key': '17980'
                                }, _createElement('figure', { 'className': 'listItem-figure' }, _createElement('img', {
                                    'className': 'listItem-image lazyautosizes lazyloaded',
                                    'src': this.imageOrDefault(this.images_image_thumbnail1 || this.images_image_thumbnail2),
                                    'alt': this.removeHTML(this.name),
                                    'title': this.removeHTML(this.name),
                                    'onError': this.onImageError
                                })), _createElement('div', { 'className': 'listItem-body' }, _createElement('div', { 'className': 'listItem-content' }, _createElement('div', { 'className': 'listItem-details' }, _createElement('h4', { 'className': 'listItem-title' }, _createElement('a', { 'href': this.custom_url }, _createElement('span', { dangerouslySetInnerHTML: { __html: this.name } })))), _createElement('div', { 'className': 'listItem-actions' }, this.Application_Support_Mounting_Size ? _createElement('p', { 'key': '2494' }, '\n          Mounting Size: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.Application_Support_Mounting_Size } })) : null, this.Thread_Size ? _createElement('p', { 'key': '2687' }, 'Thread Size: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.Thread_Size } })) : null, this.Finish ? _createElement('p', { 'key': '2814' }, 'Finish: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.Finish } })) : null, _createElement('div', { 'className': 'listItem-price' }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                                    'data-product-rrp-without-tax': true,
                                    'className': 'price price--rrp'
                                }, '\n              ', this.formatPrice(this.retail_price), '\n            '), _createElement('span', {
                                    'data-product-price-without-tax': true,
                                    'className': 'price price--withoutTax'
                                }, '\n              ', this.formatPrice(this.price), ' ', this.Quantity_Sold_As, '\n            ')), _createElement('div', { 'className': 'productView-rating' }, [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'productView-rating',
                                            'key': '34140'
                                        },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI2.bind(this)),
                                        _createElement('span', { 'className': 'productView-reviewLink' }, !this.review_count ? ['(No reviews yet)'] : null, this.review_count ? [
                                            '(',
                                            this.review_count,
                                            ' reviews)'
                                        ] : null)
                                    ])])), _createElement('a', {
                                    'className': 'button button--small card-figcaption-button quickview',
                                    'data-product-id': this.id
                                }, '\n          Quick view\n        '), !this.has_options ? [_createElement('a', {
                                        'href': '/cart.php?action=add&product_id=' + this.id,
                                        'className': 'button button--small card-figcaption-button',
                                        'key': '41801'
                                    }, '\n            ', this.availability === 'preorder' ? 'Pre-Order Now' : 'Add to Cart', '\n          ')] : null, this.has_options ? [_createElement('a', {
                                        'href': this.custom_url,
                                        'className': 'button button--small card-figcaption-button',
                                        'key': '44941'
                                    }, 'Learn More')] : null, _createElement('a', {
                                    'className': 'button wlist-btn',
                                    'href': '/wishlist.php?action=add&product_id=' + this.id
                                }, '\n          Add to Wish List\n        ')))))] : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': (this.view === 'grid' ? 'grid-view' : 'list-view') + ' cm_SearchResult' }, [this.message(function () {
                        return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                                'href': 'mailto:support@convermax.com',
                                'className': 'cm_message-link'
                            }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                                'className': 'cm_message__incompatible',
                                'key': '4950'
                            }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                                'className': 'cm_message__universal',
                                'key': '7690'
                            }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                                'tabIndex': '0',
                                'className': 'cm_message-link',
                                'href': this.searchByVehicleUrl
                            }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                            'className': 'cmTemplate_badRequest',
                            'key': '1136'
                        }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver
                        }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                            'className': 'cmTemplate_corrected',
                            'key': '1554'
                        }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                            'className': 'cmTemplate_networkError',
                            'key': '1816'
                        }, 'Network error\n', this.error ? [
                            '(id: ',
                            this.error.userId,
                            ', timestamp: ',
                            this.error.timestamp,
                            ', instance: ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                            'className': 'cmTemplate_nothing',
                            'key': '2108'
                        }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '21941' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                                '\n        for your ',
                                _createElement('b', { 'key': '22951' }, '\'', this.vehicleString, '\'.')
                            ] : null, this.query ? [
                                ' for the entered VIN number ',
                                _createElement('b', { 'key': '24421' }, '\'', this.query, '\'.')
                            ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '25781' }, '\n      No results were found for your\n      ', this.query ? [
                                ' query ',
                                _createElement('b', { 'key': '26891' }, '\'', this.query, '\'.')
                            ] : null, !this.query ? [
                                ' query ',
                                _createElement('b', { 'key': '27801' }, '\'', this.search, '\'.')
                            ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                            'className': 'cmTemplate_timeout',
                            'key': '2908'
                        }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                            'className': 'cmTemplate_partial',
                            'key': '3123'
                        }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                            'className': 'cmTemplate_unknown',
                            'key': '3352'
                        }, 'We failed to process your request', this.error ? [
                            '\n  (',
                            this.error.status ? [
                                _createElement('b', { 'key': '34980' }, 'status:'),
                                ' ',
                                this.error.status,
                                ', '
                            ] : null,
                            this.error.userId ? [
                                _createElement('b', { 'key': '35850' }, 'id:'),
                                ' ',
                                this.error.userId,
                                ', '
                            ] : null,
                            this.error.timestamp ? [
                                _createElement('b', { 'key': '36680' }, 'timestamp:'),
                                ' ',
                                this.error.timestamp,
                                ', '
                            ] : null,
                            _createElement('b', { 'key': '34634' }, 'instance:'),
                            ' ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement.apply(this, [
                    'ul',
                    { 'className': (this.view === 'grid' ? 'productGrid' : 'productList') + ' cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ]));
            }, {
                widgetName: 'results',
                items: undefined
            })], [this.pagination(function () {
                function repeatButtons1(buttons, buttonsIndex) {
                    return [buttons(function () {
                            return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', {
                                'href': this.pageLink,
                                'className': 'pagination-link'
                            }, _createElement('i', {
                                'className': 'icon',
                                'aria-hidden': 'true'
                            }, _createElement('svg', {}, _createElement('use', {
                                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                'xlinkHref': '#icon-chevron-left'
                            }))), '\n        Previous\n      ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', {
                                'href': this.pageLink,
                                'className': 'pagination-link'
                            }, '\n        Next\n        ', _createElement('i', {
                                'className': 'icon',
                                'aria-hidden': 'true'
                            }, _createElement('svg', {}, _createElement('use', {
                                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                'xlinkHref': '#icon-chevron-right'
                            }))))) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('a', {
                                'href': this.pageLink,
                                'className': 'pagination-link',
                                'key': '121'
                            }, this.page) : null, !this.isActive ? _createElement('a', {
                                'href': this.pageLink,
                                'className': 'pagination-link',
                                'key': '215'
                            }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_pagination' }, _createElement('div', { 'className': 'pagination nxt-address' }, _createElement.apply(this, [
                    'ul',
                    { 'className': 'pagination-list cmRepeater_buttons' },
                    _map(this.buttons, repeatButtons1.bind(this))
                ])));
            }, {
                widgetName: 'undefined',
                items: undefined
            })]))
    ] : null);
}
        export const componentNames = ["cm:message","cm:filterChips","cm:FilterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:SearchResult","cm:pagination"]
const pageSizeField = 'res_per_page';

const sortField = 'sort_by_field';
const sortValuesMap = {
  'SKU': 'sku',
  'Extended+Length': 'Extended Length A',
  'Stroke+Length': 'Stroke C',
  'Compressed+Length': 'Compressed Length B',
  'Force': 'Force',
  'Shaft+Connector': 'Shaft Connection',
  'Body+Connector': 'Body Connection',
  'Price+%5BLow+-+High%5D': '',
  'Price+%5BHigh+-+Low%5D': '',
};

const textQueryField = 'search_query';
const partNumberQueryField = 'part-number';

const searchBySizeFieldsMap = {
  Customfieldextendedlengthain: 'Extended Length A',
  Customfieldforcelbs: 'Force',
  Customfieldcompressedlengthbin: 'Compressed Length B',
  Customfieldstrokecin: 'Stroke C',
  Customfieldendfittingtype: 'End Fitting Type',
  Customfieldendfittingsize: 'End Fitting Size',
  Customfieldfinish: 'Finish',
};

const searchByYMMTFieldsMap = {
  Year: 'Year',
  Make: 'Make',
  Model: 'Model',
  Trim: 'Trim',
};

export default {
  parsingNeeded() {
    const { search: locationSearch, hash: locationHash } = window.location;

    return (
      locationSearch.includes(partNumberQueryField) ||
      locationSearch.includes(textQueryField) ||
      !!locationHash
    );
  },
  parse() {
    let query;
    let sort;
    let pageSize;
    const selection = [];

    const { search: locationSearch, hash: locationHash } = window.location;

    // https://www.liftsupportsdepot.com/part-number-search/?part-number=4048-W
    // https://www.liftsupportsdepot.com/search/search-results/?search_query=lift+support
    if (locationSearch.includes(partNumberQueryField) || locationSearch.includes(textQueryField)) {
      query = locationSearch.split('=')[1].replaceAll('+', ' ');
    }

    locationHash
      .replace('#?', '')
      .split('&')
      .forEach((param) => {
        const [field, value] = param.split('=');

        if (field === sortField) {
          sort = sortValuesMap[value];
        }

        if (field === pageSizeField) {
          pageSize = value;
        }

        const sizeField = searchBySizeFieldsMap[field];
        if (sizeField) {
          // there are always will be both FROM and TO
          // https://www.liftsupportsdepot.com/custom-search/#?Customfieldextendedlengthain=2%7C%7C20&Customfieldcompressedlengthbin=2||20
          if (value.includes('%7C%7C') || value.includes('||')) {
            const valueParts = value.includes('%7C%7C') ? value.split('%7C%7C') : value.split('||');
            selection.push({ field: sizeField, term: `${valueParts[0]} TO ${valueParts[1]}` });
          }
          // https://www.liftsupportsdepot.com/custom-search/#?Customfieldendfittingtype=Ball+Socket
          else {
            selection.push({ field: sizeField, term: value.replaceAll('+', ' ') });
          }
        }

        const ymmtField = searchByYMMTFieldsMap[field];
        if (ymmtField) {
          // https://www.liftsupportsdepot.com/year-model-search/#?Year=2011&Make=GMC&Model=SAVANA%203500&Trim=BASE%20EXTENDED%20CARGO%20VAN%204-DOOR
          selection.push({ field: ymmtField, term: value.replaceAll('%20', ' ') });
        }
      });

    // use JSON.parse(JSON.stringify()) to filter undefined
    return JSON.parse(JSON.stringify({ selection, query, sort, pageSize }));
  },
};
